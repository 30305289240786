
import { Comment } from '@icepanel/platform-api-client'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Ref, Watch } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

import Menu from '@/components/menu.vue'
import Tabs, { ITab } from '@/components/tabs.vue'
import { AlertModule } from '@/modules/alert/store'
import { EditorModule } from '@/modules/editor/store'
import { LandscapeModule } from '@/modules/landscape/store'
import { OrganizationModule } from '@/modules/organization/store'
import { ShareModule } from '@/modules/share/store'
import { UserModule } from '@/modules/user/store'
import { VersionModule } from '@/modules/version/store'

import * as analytics from '../../helpers/analytics'
import { CommentModule } from '../../store'
import ByStatus from './by-status.vue'

@Component({
  components: {
    ByStatus,
    Menu,
    Tabs
  },
  name: 'CommentListMenu'
})
export default class extends Vue {
  alertModule = getModule(AlertModule, this.$store)
  commentModule = getModule(CommentModule, this.$store)
  editorModule = getModule(EditorModule, this.$store)
  organizationModule = getModule(OrganizationModule, this.$store)
  landscapeModule = getModule(LandscapeModule, this.$store)
  shareModule = getModule(ShareModule, this.$store)
  userModule = getModule(UserModule, this.$store)
  versionModule = getModule(VersionModule, this.$store)

  @Ref() readonly menuRef!: Menu

  @Prop() readonly activator?: () => HTMLElement | { $el: HTMLElement } | undefined

  loading = false
  defaultTab = 'user'

  get commentsListMenu () {
    return this.$queryValue('comments_list_menu')
  }

  get commentsHidden () {
    return this.$queryValue('comments_hidden')
  }

  get currentLandscapeId () {
    return this.$params.landscapeId || this.currentVersion.landscapeId
  }

  get currentVersionId () {
    return this.$params.versionId || this.currentShareLink?.versionId || 'latest'
  }

  get currentShareLink () {
    return this.shareModule.shareLinks.find(o => o.shortId === this.$params.shortId)
  }

  get currentVersion () {
    return this.versionModule.versions.find(o => o.id === this.currentVersionId || o.tags.includes(this.currentVersionId))!
  }

  get currentLandscape () {
    return this.landscapeModule.landscapes.find(o => o.id === this.currentLandscapeId)!
  }

  get currentLandscapePermission () {
    return this.landscapeModule.landscapePermission(this.currentLandscape)
  }

  get currentOrganization () {
    return this.organizationModule.organizations.find(o => o.id === this.currentLandscape.organizationId)!
  }

  get comments () {
    return Object.values(this.commentModule.comments)
  }

  get activeComments () {
    return Object.values(this.commentModule.activeComments)
  }

  get yourComments () {
    return this.comments.filter(o => this.userModule.user && o.createdById === this.userModule.user.id)
  }

  get mentionedComments () {
    return this.comments.filter(o => this.userModule.user && o.mentionedUserIds.includes(this.userModule.user.id))
  }

  get tabs (): ITab[] {
    return [
      {
        caption: this.yourComments.filter(o => o.body.status === 'open' || o.body.status === 'active').length,
        id: 'user',
        text: 'Your comments',
        to: { query: this.$setQuery({ comments_list_menu: 'user' }) }
      },
      {
        badge: this.mentionedComments.filter(o => o.body.status === 'open' || o.body.status === 'active').length,
        id: 'mentions',
        text: 'Mentions',
        to: { query: this.$setQuery({ comments_list_menu: 'mentions' }) }
      },
      {
        caption: this.comments.filter(o => o.body.status === 'open' || o.body.status === 'active').length,
        id: 'all',
        text: 'All comments',
        to: { query: this.$setQuery({ comments_list_menu: 'all' }) }
      }
    ]
  }

  @Watch('activeComments')
  onActiveCommentsChanged (activeComments: Comment[], prevActiveComments: Comment[]) {
    if (this.menuRef.visible) {
      const activeCommentsKey = activeComments.map(o => o.id).sort().join('-')
      const prevActiveCommentsKey = prevActiveComments.map(o => o.id).sort().join('-')

      if (activeCommentsKey !== prevActiveCommentsKey) {
        this.commentModule.commentsList({
          landscapeId: this.currentLandscape.id,
          status: ['open', 'active', 'resolved', 'dismissed'],
          versionId: this.currentVersion.id
        })
      }
    }
  }

  mounted () {
    const lastTab = localStorage.getItem('commentsListMenuTab')
    if (lastTab && this.tabs.some(o => o.id === lastTab)) {
      this.defaultTab = lastTab
    }
  }

  open () {
    this.load()
  }

  opened () {
    analytics.commentsMenu.track(this, {
      landscapeId: [this.currentLandscape.id],
      organizationId: [this.currentLandscape.organizationId]
    })
  }

  async load () {
    try {
      this.loading = true

      await this.commentModule.commentsList({
        landscapeId: this.currentLandscape.id,
        status: ['open', 'active', 'resolved', 'dismissed'],
        versionId: this.currentVersion.id
      })
    } catch (err: any) {
      this.alertModule.pushAlert({
        color: 'error',
        message: err.message
      })
    } finally {
      this.loading = false
    }
  }

  saveTab (id: string) {
    this.defaultTab = id
    localStorage.setItem('commentsListMenuTab', id)
  }
}
