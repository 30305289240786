
import Vue from 'vue'
import Component from 'vue-class-component'
import { getModule } from 'vuex-module-decorators'

import { LandscapeModule } from '@/modules/landscape/store'
import { ShareModule } from '@/modules/share/store'
import { VersionModule } from '@/modules/version/store'

import { CommentModule } from '../../store'
import CommentsListMenu from '../comments-list-menu/index.vue'

@Component({
  components: {
    CommentsListMenu
  },
  name: 'CommentAppbar'
})
export default class extends Vue {
  commentModule = getModule(CommentModule, this.$store)
  landscapeModule = getModule(LandscapeModule, this.$store)
  shareModule = getModule(ShareModule, this.$store)
  versionModule = getModule(VersionModule, this.$store)

  open = false

  get currentLandscapeId () {
    return this.$params.landscapeId || this.currentVersion?.landscapeId
  }

  get currentVersionId () {
    return this.$params.versionId || this.currentShareLink?.versionId || 'latest'
  }

  get currentShareLink () {
    return this.shareModule.shareLinks.find(o => o.shortId === this.$params.shortId)
  }

  get currentVersion () {
    return this.versionModule.versions.find(o => o.id === this.currentVersionId || o.tags.includes(this.currentVersionId))
  }

  get currentLandscape () {
    return this.landscapeModule.landscapes.find(o => o.id === this.currentLandscapeId)
  }

  get activeComments () {
    return Object.values(this.commentModule.activeComments)
  }
}
