
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Ref, Watch } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

import { deltaToMarkdown, markdownToDelta } from '@/helpers/markdown-delta'
import { AlertModule } from '@/modules/alert/store'
import { LandscapeModule } from '@/modules/landscape/store'
import { OrganizationModule } from '@/modules/organization/store'
import { ShareModule } from '@/modules/share/store'
import { UserModule } from '@/modules/user/store'
import { VersionModule } from '@/modules/version/store'
import Quill, { QuillInstance } from '@/plugins/quill'

@Component({
  name: 'CommentContent'
})
export default class extends Vue {
  alertModule = getModule(AlertModule, this.$store)
  landscapeModule = getModule(LandscapeModule, this.$store)
  organizationModule = getModule(OrganizationModule, this.$store)
  shareModule = getModule(ShareModule, this.$store)
  userModule = getModule(UserModule, this.$store)
  versionModule = getModule(VersionModule, this.$store)

  @Ref() readonly container!: HTMLElement
  @Ref() readonly editor!: HTMLElement

  @Prop() readonly value?: string
  @Prop() readonly placeholder?: string
  @Prop({ default: false, type: Boolean }) readonly disabled!: boolean
  @Prop({ default: false, type: Boolean }) readonly readonly!: boolean
  @Prop({ default: false, type: Boolean }) readonly autofocus!: boolean

  quill!: QuillInstance
  quillMentionOpen = false

  textChangedListener?: () => void

  get currentLandscapeId () {
    return this.$params.landscapeId || this.currentVersion.landscapeId
  }

  get currentVersionId () {
    return this.$params.versionId || this.currentShareLink?.versionId || 'latest'
  }

  get currentShareLink () {
    return this.shareModule.shareLinks.find(o => o.shortId === this.$params.shortId)
  }

  get currentVersion () {
    return this.versionModule.versions.find(o => o.id === this.currentVersionId || o.tags.includes(this.currentVersionId))!
  }

  get currentLandscape () {
    return this.landscapeModule.landscapes.find(o => o.id === this.currentLandscapeId)!
  }

  @Watch('placeholder')
  onPlaceholderChanged (placeholder: string) {
    this.quill.root.dataset.placeholder = placeholder
  }

  @Watch('disabled')
  onDisableChanged (disabled: boolean) {
    if (disabled) {
      this.quill.disable()
    } else if (!this.readonly) {
      this.quill.enable()
      this.quill.focus()
    }
  }

  @Watch('value')
  onValueChanged (newVal: string, oldVal: string) {
    if (newVal !== oldVal) {
      this.setValue(newVal)
    }
  }

  mounted () {
    this.quill = new Quill(this.editor, {
      modules: {
        clickLink: true,
        keyboard: {
          bindings: {
            enter: {
              handler: () => {
                if (this.quillMentionOpen) {
                  this.quill.getModule('mention').selectItem()
                } else if (this.quill.getText().trim().length) {
                  this.$emit('submit')
                }
                return false
              },
              key: 'Enter'
            },
            mention: {
              handler: () => this.openMentionMenu(),
              key: '@',
              shiftKey: true
            }
          }
        },
        magicUrl: true,
        mention: {
          allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
          mentionDenotationChars: this.readonly ? [] : ['@'],
          onClose: () => {
            this.quillMentionOpen = false
          },
          onOpen: () => {
            this.quillMentionOpen = true
          },
          positioningStrategy: 'fixed',
          source: (searchTerm: string, renderList: (item: { id: string, value: string }[]) => void) => {
            const users = Object
              .entries(this.organizationModule.organizationUsers[this.currentLandscape.organizationId])
              .filter(([id, o]) =>
                id !== this.userModule.user?.id &&
                (!o.landscapePermissions || o.landscapePermissions[this.currentLandscapeId]) &&
                (!searchTerm || o.name?.toLowerCase().includes(searchTerm.toLowerCase()) || o.email.toLowerCase().includes(searchTerm.toLowerCase()) || o.permission.includes(searchTerm.toLowerCase()))
              )
              .map(([id, o]) => ({
                id,
                value: o.name || ''
              }))
            renderList(users)
          }
        },
        toolbar: false
      },
      placeholder: this.placeholder,
      readOnly: this.readonly,
      theme: 'snow'
    })

    this.textChangedListener = () => this.$emit('text-change', this.getValue())
    this.quill.on('text-change', this.textChangedListener)

    if (this.value) {
      this.setValue(this.value)
    }

    if (this.disabled) {
      this.quill.disable()
    } else if (!this.readonly && this.value && this.autofocus) {
      this.quill.focus()
      this.quill.setSelection(this.value.length, 0)
    } else if (!this.readonly) {
      this.quill.focus()
    }
  }

  destroyed () {
    if (this.textChangedListener) {
      this.quill.off('text-change', this.textChangedListener)
    }

    this.quill.getModule('mention').hideMentionList()
  }

  getValue () {
    return deltaToMarkdown(this.quill.getContents())
  }

  setValue (value: string) {
    this.quill.setContents(markdownToDelta(value))
  }

  openMentionMenu () {
    this.quill.getModule('mention').openMenu('@')
  }
}
