
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

import { DiagramModule } from '@/modules/diagram/store'

@Component({
  name: 'DiagramThumbnailMenu'
})
export default class extends Vue {
  diagramModule = getModule(DiagramModule, this.$store)

  @Prop({ default: null }) readonly diagramId!: string | null
  @Prop() readonly menuProps?: any
  @Prop({ default: false, type: Boolean }) readonly openOnLoad!: boolean
  @Prop() readonly openDelay?: number

  visible = false
  visibleTimer?: number

  get diagram () {
    return this.diagramId ? this.diagramModule.diagrams[this.diagramId] : undefined
  }

  get diagramThumbnailUrl () {
    return this.diagramId ? this.diagramModule.diagramThumbnails[this.diagramId]?.url : undefined
  }

  @Watch('diagramId')
  onDiagramIdChanged () {
    this.resetVisibleTimer()
  }

  destroy () {
    clearTimeout(this.visibleTimer)
  }

  loadThumbnail () {
    if (this.diagram && !this.diagramThumbnailUrl) {
      try {
        this.diagramModule.diagramThumbnailGet({
          diagramId: this.diagram.id,
          landscapeId: this.diagram?.landscapeId,
          versionId: this.diagram?.versionId
        })
      } catch (err) {}
    }
  }

  resetVisibleTimer () {
    if (this.openDelay) {
      this.visible = false
      clearTimeout(this.visibleTimer)
      this.visibleTimer = window.setTimeout(() => {
        this.visible = true
      }, this.openDelay)
    } else {
      this.visible = true
    }
  }
}
