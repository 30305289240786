import * as analytics from '@icepanel/app-analytics'
import { DiagramType, ModelObjectType, Theme } from '@icepanel/platform-api-client'

export const diagramType: analytics.Property<DiagramType> = {
  displayName: 'Diagram type',
  examples: ['context-diagram', 'app-diagram', 'component-diagram'],
  name: 'diagram_type',
  required: true,
  type: 'string'
}
export const diagramFlowId: analytics.Property<string> = {
  displayName: 'Diagram flow ID',
  name: 'diagram_flow_id',
  required: true,
  type: 'string'
}
export const diagramGroupModelType: analytics.Property<ModelObjectType> = {
  displayName: 'Diagram group model type',
  examples: ['system', 'app', 'store'],
  name: 'diagram_group_model_type',
  required: true,
  type: 'string'
}
export const diagramTheme: analytics.Property<Theme> = {
  displayName: 'Diagram theme',
  name: 'diagram_theme',
  required: true,
  type: 'string'
}
