import * as analytics from '@icepanel/app-analytics'

import { landscapeId } from '@/modules/landscape/helpers/analytics-group'
import { organizationId } from '@/modules/organization/helpers/analytics-group'

/**
 * Menus
 */

export const commentsMenu = new analytics.Event({
  displayName: 'Comments menu',
  name: 'comments_menu',
  tags: [analytics.Tags.Menu]
}, {
  landscapeId,
  organizationId
})
