
import { Comment } from '@icepanel/platform-api-client'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

import ListItem from './list-item.vue'

@Component({
  components: {
    ListItem
  },
  name: 'CommentList'
})
export default class extends Vue {
  @Prop() readonly height!: number | string
  @Prop() readonly comments!: Comment[]
}
