import * as fasAngleLeft from '@fortawesome/free-solid-svg-icons/faAngleLeft'
import * as fasEdit from '@fortawesome/free-solid-svg-icons/faEdit'
import * as fasLongArrowAltLeft from '@fortawesome/free-solid-svg-icons/faLongArrowAltLeft'
import * as fasLongArrowAltUp from '@fortawesome/free-solid-svg-icons/faLongArrowAltUp'
import * as fasPlus from '@fortawesome/free-solid-svg-icons/faPlus'
import * as fasSearchPlus from '@fortawesome/free-solid-svg-icons/faSearchPlus'
import * as fasSyncAlt from '@fortawesome/free-solid-svg-icons/faSyncAlt'

export default [
  fasAngleLeft.definition,
  fasEdit.definition,
  fasLongArrowAltLeft.definition,
  fasLongArrowAltUp.definition,
  fasPlus.definition,
  fasSearchPlus.definition,
  fasSyncAlt.definition
] as const
