
import { Comment } from '@icepanel/platform-api-client'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

import List from '../list.vue'

@Component({
  components: {
    List
  },
  name: 'CommentListMenuByStatus'
})
export default class extends Vue {
  @Prop() readonly comments!: Comment[]

  searchTerm = ''
  defaultSection = 'open'
  selectedIndex = 0

  get sections () {
    const search = this.searchTerm.toLowerCase()
    const comments = this.comments.filter(o => !search || o.body.type.includes(search) || o.body.content.includes(search))
    return [
      {
        icon: '$custom-solid-comment-alt-dots',
        id: 'open',
        items: comments.filter(o => o.body.status === 'open' || o.body.status === 'active'),
        title: 'Open comments'
      },
      {
        icon: '$custom-solid-comment-alt-check',
        id: 'resolved',
        items: comments.filter(o => o.body.status === 'resolved' || o.body.status === 'dismissed'),
        title: 'Resolved comments'
      }
    ]
  }

  created () {
    const lastSection = localStorage.getItem('commentsListMenuLinksSectionKey')
    if (lastSection && this.sections.some(o => o.id === lastSection)) {
      this.defaultSection = lastSection
    }
    this.selectedIndex = this.sections.findIndex(o => o.id === this.defaultSection)
  }

  saveSection (id: string) {
    this.defaultSection = id
    localStorage.setItem('commentsListMenuLinksSectionKey', id)
  }
}
