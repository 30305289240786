import {
  Comment,
  CommentBody,
  CommentPartial,
  CommentRepliesService,
  CommentReply,
  CommentReplyPartial,
  CommentReplyRequired,
  CommentRequired,
  CommentsService,
  SocketClientCommentsSubscribeBody
} from '@icepanel/platform-api-client'
import Vue from 'vue'
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'

import getFirestoreId from '@/helpers/firestore-id'
import randomId from '@/helpers/random-id'
import Status from '@/helpers/status'
import { ServerEvents, Socket } from '@/plugins/socket'

export interface ICommentModule {
  comments: Record<string, Comment>
  commentsCommit: Record<string, number>
  activeComments: Record<string, Comment>
  activeCommentsCommit: Record<string, number>
  commentReplies: Record<string, CommentReply>

  commentsSubscriptionStatus: Status<{ subscriptionId: string, landscapeId: string, versionId: string, unsubscribe: () => void, reconnect: boolean }, { subscriptionId: string, landscapeId: string, versionId: string, unsubscribe: () => void }>
}

const name = 'comment'

@Module({
  name,
  namespaced: true
})
export class CommentModule extends VuexModule implements ICommentModule {
  static namespace = name

  comments: Record<string, Comment> = {}
  commentsCommit: Record<string, number> = {}
  activeComments: Record<string, Comment> = {}
  activeCommentsCommit: Record<string, number> = {}
  commentReplies: Record<string, CommentReply> = {}

  commentsSubscriptionStatus = new Status<{ subscriptionId: string, landscapeId: string, versionId: string, unsubscribe:() => void, reconnect: boolean }, { subscriptionId: string, landscapeId: string, versionId: string, unsubscribe: () => void }>()

  get generateCommentId () {
    return () => getFirestoreId('comment')
  }

  get generateComment () {
    return (landscapeId: string, versionId: string, props: CommentRequired, id = this.generateCommentId()): { comment: Comment, commentUpsert: CommentRequired & CommentPartial } => {
      const commitVal = typeof this.activeCommentsCommit[id] === 'number' ? this.activeCommentsCommit[id] : this.commentsCommit[id]
      const commit = typeof commitVal === 'number' ? commitVal + 1 : props.commit || 0
      const defaultHandleId = randomId()
      return {
        comment: {
          diagrams: {},
          handleId: defaultHandleId,
          mentionedUserIds: [],
          replyCount: 0,
          ...props,
          commit,
          createdAt: new Date().toISOString(),
          createdBy: 'user',
          createdById: this.context.rootState.user.user?.id || '',
          id,
          landscapeId,
          updatedAt: new Date().toISOString(),
          updatedBy: 'user',
          updatedById: this.context.rootState.user.user?.id || '',
          version: -1,
          versionId
        },
        commentUpsert: {
          handleId: defaultHandleId,
          mentionedUserIds: [],
          ...props,
          commit
        }
      }
    }
  }

  get generateCommentCommit () {
    return (id: string, props: Omit<CommentPartial, 'commit'>): { comment: Comment, commentUpdate: CommentPartial } => {
      const comment = structuredClone(this.activeComments[id] || this.comments[id])
      if (comment) {
        const commit = comment.commit + 1
        if ((props.body && props.body.content !== comment.body.content) || props.mentionedUserIds) {
          comment.updatedAt = new Date().toISOString()
          comment.updatedBy = 'user'
          comment.updatedById = this.context.rootState.user.user?.id || ''
        }
        return {
          comment: {
            ...comment,
            ...props,
            commit
          },
          commentUpdate: {
            ...props,
            commit
          }
        }
      } else {
        throw new Error(`Could not find comment ${id}`)
      }
    }
  }

  get socket (): Socket {
    return this.context.rootState.socket.socket
  }

  @Mutation
  setComments (comments: Comment[] | Record<string, Comment>) {
    this.comments = Object
      .values(comments)
      .reduce<ICommentModule['comments']>((p, c) => ({
        ...p,
        [c.id]: c
      }), {})
    this.commentsCommit = Object.values(this.comments).reduce<ICommentModule['commentsCommit']>((p, c) => ({
      ...p,
      [c.id]: c.commit
    }), {})
  }

  @Mutation
  setComment (comment: Comment) {
    Vue.set(this.comments, comment.id, comment)
    Vue.set(this.commentsCommit, comment.id, comment.commit)
  }

  @Mutation
  setCommentVersion (comment: Comment) {
    if (
      this.commentsCommit[comment.id] === undefined ||
      comment.commit > this.commentsCommit[comment.id] ||
      (comment.commit === this.commentsCommit[comment.id] && this.comments[comment.id] && comment.version > this.comments[comment.id].version)
    ) {
      Vue.set(this.comments, comment.id, comment)
      Vue.set(this.commentsCommit, comment.id, comment.commit)
    }
  }

  @Mutation
  removeComment (comment: string | { id: string, commit: number }) {
    if (typeof comment === 'string') {
      Vue.delete(this.comments, comment)
    } else if (comment.commit >= this.commentsCommit[comment.id]) {
      Vue.delete(this.comments, comment.id)
      this.commentsCommit[comment.id] = comment.commit
    }
  }

  @Mutation
  setActiveComments (comments: Comment[] | Record<string, Comment>) {
    this.activeComments = Object
      .values(comments)
      .reduce<ICommentModule['activeComments']>((p, c) => ({
        ...p,
        [c.id]: c
      }), {})
    this.activeCommentsCommit = Object.values(this.activeComments).reduce<ICommentModule['activeCommentsCommit']>((p, c) => ({
      ...p,
      [c.id]: c.commit
    }), {})
  }

  @Mutation
  setActiveComment (comment: Comment) {
    Vue.set(this.activeComments, comment.id, comment)
    Vue.set(this.activeCommentsCommit, comment.id, comment.commit)
  }

  @Mutation
  setActiveCommentVersion (comment: Comment) {
    if (
      this.activeCommentsCommit[comment.id] === undefined ||
      comment.commit > this.activeCommentsCommit[comment.id] ||
      (comment.commit === this.activeCommentsCommit[comment.id] && this.activeComments[comment.id] && comment.version > this.activeComments[comment.id].version)
    ) {
      Vue.set(this.activeComments, comment.id, comment)
      Vue.set(this.activeCommentsCommit, comment.id, comment.commit)
    }
  }

  @Mutation
  removeActiveComment (comment: string | Pick<Comment, 'id' | 'commit'>) {
    if (typeof comment === 'string') {
      Vue.delete(this.activeComments, comment)
    } else if (comment.commit >= this.activeCommentsCommit[comment.id]) {
      Vue.delete(this.activeComments, comment.id)
      this.activeCommentsCommit[comment.id] = comment.commit
    }
  }

  @Mutation
  setCommentReplies (commentReplies: CommentReply[] | Record<string, CommentReply>) {
    this.commentReplies = {}
    Object.values(commentReplies).forEach(o => {
      Vue.set(this.commentReplies, o.id, o)
    })
  }

  @Mutation
  setCommentReply (commentReply: CommentReply) {
    Vue.set(this.commentReplies, commentReply.id, commentReply)
  }

  @Mutation
  removeCommentReply (commentReplyId: string) {
    Vue.delete(this.commentReplies, commentReplyId)
  }

  @Mutation
  setCommentsSubscriptionStatus (...params: Parameters<typeof this.commentsSubscriptionStatus.set>) {
    this.commentsSubscriptionStatus.set(...params)
  }

  @Mutation
  commentsUnsubscribe () {
    this.commentsSubscriptionStatus.loadingInfo?.unsubscribe?.()
    this.commentsSubscriptionStatus.successInfo?.unsubscribe?.()
    this.commentsSubscriptionStatus.set(Status.idle())
  }

  @Action({ rawError: true })
  async commentsSubscribe (body: SocketClientCommentsSubscribeBody & { reconnect: boolean }) {
    this.commentsSubscriptionStatus.loadingInfo?.unsubscribe?.()
    this.commentsSubscriptionStatus.successInfo?.unsubscribe?.()

    this.context.commit('setCommentsSubscriptionStatus', Status.loading({
      landscapeId: body.landscapeId,
      reconnect: body.reconnect,
      versionId: body.versionId
    }))

    const initialValue: Comment[] = []

    await new Promise<void>((resolve, reject) => {
      this.socket.emit('comments-subscribe', {
        landscapeId: body.landscapeId,
        versionId: body.versionId
      }, (err, reply) => {
        if (reply) {
          const commentInitialValueListener: ServerEvents['comment-initial-value'] = ({ finalValue, comments, subscriptionId }) => {
            if (reply.subscriptionId === subscriptionId) {
              initialValue.push(...comments)

              if (finalValue) {
                this.context.commit('setActiveComments', initialValue)

                this.context.commit('setCommentsSubscriptionStatus', Status.success({
                  landscapeId: body.landscapeId,
                  subscriptionId: reply.subscriptionId,
                  unsubscribe,
                  versionId: body.versionId
                }))

                this.context.dispatch('socket/checkSocket', undefined, { root: true })
              }
            }
          }
          const commentAddedListener: ServerEvents['comment-added'] = ({ comment, subscriptionId }) => {
            if (reply.subscriptionId === subscriptionId) {
              this.context.commit('setCommentVersion', comment)
              this.context.commit('setActiveCommentVersion', comment)
            }
            if (comment.createdById && comment.createdById !== this.context.rootState.user.user?.id) {
              this.context.commit('editor/resetTaskLists', undefined, { root: true })
            }
          }
          const commentModifiedListener: ServerEvents['comment-modified'] = ({ comment, subscriptionId }) => {
            if (reply.subscriptionId === subscriptionId) {
              this.context.commit('setCommentVersion', comment)
              this.context.commit('setActiveCommentVersion', comment)
            }
            if (comment.updatedById && comment.updatedById !== this.context.rootState.user.user?.id) {
              this.context.commit('editor/resetTaskLists', undefined, { root: true })
            }
          }
          const commentRemovedListener: ServerEvents['comment-removed'] = ({ comment, subscriptionId }) => {
            if (reply.subscriptionId === subscriptionId) {
              this.context.commit('removeActiveComment', comment)
            }
            if (comment.deletedById && comment.deletedById !== this.context.rootState.user.user?.id) {
              this.context.commit('editor/resetTaskLists', undefined, { root: true })
            }
          }

          const socketId = this.socket.id

          const unsubscribe = () => {
            this.socket.off('comment-initial-value', commentInitialValueListener)
            this.socket.off('comment-added', commentAddedListener)
            this.socket.off('comment-modified', commentModifiedListener)
            this.socket.off('comment-removed', commentRemovedListener)

            if (this.socket.id === socketId && this.socket.connected) {
              this.socket.emit('comments-unsubscribe', {
                subscriptionId: reply.subscriptionId
              }, err => {
                if (err) {
                  console.error('comments unsubscribe error', err)
                }
              })
            }
          }

          this.socket.on('comment-initial-value', commentInitialValueListener)
          this.socket.on('comment-added', commentAddedListener)
          this.socket.on('comment-modified', commentModifiedListener)
          this.socket.on('comment-removed', commentRemovedListener)

          this.context.commit('setCommentsSubscriptionStatus', Status.loading({
            landscapeId: body.landscapeId,
            reconnect: body.reconnect,
            subscriptionId: reply.subscriptionId,
            unsubscribe,
            versionId: body.versionId
          }))

          resolve()
        } else {
          const error = err || 'Comment subscription not provided'
          this.context.commit('setCommentsSubscriptionStatus', Status.error(error))
          reject(new Error(error))
        }
      })
    })
  }

  @Action({ rawError: true })
  async commentsList ({ landscapeId, versionId, status }: { landscapeId: string, versionId: string, status?: CommentBody['status'][] }) {
    const authorization = await this.context.dispatch('user/getAuthorizationHeader', undefined, { root: true })
    const { comments } = await CommentsService.commentsList(authorization, landscapeId, versionId, status as string[] | undefined)
    this.context.commit('setComments', comments)
    return comments
  }

  @Action({ rawError: true })
  async commentCreate ({ landscapeId, versionId, props }: { landscapeId: string, versionId: string, props: CommentRequired }) {
    const authorization = await this.context.dispatch('user/getAuthorizationHeader', undefined, { root: true })
    const { comment } = await CommentsService.commentCreate(authorization, landscapeId, versionId, props)
    this.context.commit('setCommentVersion', comment)
    if (comment.body.status !== 'resolved' && comment.body.status !== 'dismissed') {
      this.context.commit('setActiveCommentVersion', comment)
    }
    return comment
  }

  @Action({ rawError: true })
  async commentUpsert ({ landscapeId, versionId, commentId, props }: { landscapeId: string, versionId: string, commentId: string, props: CommentRequired & CommentPartial }) {
    const authorization = await this.context.dispatch('user/getAuthorizationHeader', undefined, { root: true })
    const { comment } = await CommentsService.commentUpsert(authorization, landscapeId, versionId, commentId, props)
    this.context.commit('setCommentVersion', comment)
    if (comment.body.status === 'resolved' || comment.body.status === 'dismissed') {
      this.context.commit('removeActiveComment', comment)
    } else {
      this.context.commit('setActiveCommentVersion', comment)
    }
    return comment
  }

  @Action({ rawError: true })
  async commentUpdate ({ landscapeId, versionId, commentId, props }: { landscapeId: string, versionId: string, commentId: string, props: CommentPartial }) {
    const authorization = await this.context.dispatch('user/getAuthorizationHeader', undefined, { root: true })
    const { comment } = await CommentsService.commentUpdate(authorization, landscapeId, versionId, commentId, props)
    this.context.commit('setCommentVersion', comment)
    if (comment.body.status === 'resolved' || comment.body.status === 'dismissed') {
      this.context.commit('removeActiveComment', comment)
    } else {
      this.context.commit('setActiveCommentVersion', comment)
    }
    return comment
  }

  @Action({ rawError: true })
  async commentDelete ({ landscapeId, versionId, commentId }: { landscapeId: string, versionId: string, commentId: string }) {
    const authorization = await this.context.dispatch('user/getAuthorizationHeader', undefined, { root: true })
    const { commit } = await CommentsService.commentDelete(authorization, landscapeId, versionId, commentId)
    this.context.commit('removeComment', { commit, id: commentId })
    this.context.commit('removeActiveComment', { commit, id: commentId })
  }

  @Action({ rawError: true })
  async commentRepliesList ({ landscapeId, versionId, commentId }: { landscapeId: string, versionId: string, commentId: string }) {
    const authorization = await this.context.dispatch('user/getAuthorizationHeader', undefined, { root: true })
    const { commentReplies } = await CommentRepliesService.commentRepliesList(authorization, landscapeId, versionId, commentId)
    this.context.commit('setCommentReplies', commentReplies)
    return commentReplies
  }

  @Action({ rawError: true })
  async commentReplyCreate ({ landscapeId, versionId, commentId, props }: { landscapeId: string, versionId: string, commentId: string, props: CommentReplyRequired }) {
    const authorization = await this.context.dispatch('user/getAuthorizationHeader', undefined, { root: true })
    const { commentReply } = await CommentRepliesService.commentReplyCreate(authorization, landscapeId, versionId, commentId, props)
    this.context.commit('setCommentReply', commentReply)
    return commentReply
  }

  @Action({ rawError: true })
  async commentReplyUpdate ({ landscapeId, versionId, commentId, commentReplyId, props }: { landscapeId: string, versionId: string, commentId: string, commentReplyId: string, props: CommentReplyPartial }) {
    const authorization = await this.context.dispatch('user/getAuthorizationHeader', undefined, { root: true })
    const { commentReply } = await CommentRepliesService.commentReplyUpdate(authorization, landscapeId, versionId, commentId, commentReplyId, props)
    this.context.commit('setCommentReply', commentReply)
    return commentReply
  }

  @Action({ rawError: true })
  async commentReplyDelete ({ landscapeId, versionId, commentId, commentReplyId }: { landscapeId: string, versionId: string, commentId: string, commentReplyId: string }) {
    const authorization = await this.context.dispatch('user/getAuthorizationHeader', undefined, { root: true })
    await CommentRepliesService.commentReplyDelete(authorization, landscapeId, versionId, commentId, commentReplyId)
    this.context.commit('removeCommentReply', commentReplyId)
  }
}
