
import { Comment, CommentBodyType } from '@icepanel/platform-api-client'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

import { DiagramModule } from '@/modules/diagram/store'
import { LandscapeModule } from '@/modules/landscape/store'
import { ModelModule } from '@/modules/model/store'
import { OrganizationModule } from '@/modules/organization/store'
import { ShareModule } from '@/modules/share/store'
import { UserModule } from '@/modules/user/store'
import { VersionModule } from '@/modules/version/store'

import Content from './content.vue'

@Component({
  components: {
    Content
  },
  name: 'CommentListItem'
})
export default class extends Vue {
  diagramModule = getModule(DiagramModule, this.$store)
  modelModule = getModule(ModelModule, this.$store)
  organizationModule = getModule(OrganizationModule, this.$store)
  landscapeModule = getModule(LandscapeModule, this.$store)
  shareModule = getModule(ShareModule, this.$store)
  userModule = getModule(UserModule, this.$store)
  versionModule = getModule(VersionModule, this.$store)

  @Prop() readonly comment!: Comment

  get currentOrganizationId () {
    return this.$params.organizationId || this.currentLandscape?.organizationId
  }

  get currentLandscapeId () {
    return this.$params.landscapeId || this.currentVersion?.landscapeId
  }

  get currentVersionId () {
    return this.$params.versionId || this.currentShareLink?.versionId || 'latest'
  }

  get currentShareLink () {
    return this.shareModule.shareLinks.find(o => o.shortId === this.$params.shortId)
  }

  get currentVersion () {
    return this.versionModule.versions.find(o => o.id === this.currentVersionId || o.tags.includes(this.currentVersionId))
  }

  get currentLandscape () {
    return this.landscapeModule.landscapes.find(o => o.id === this.currentLandscapeId)
  }

  get diagramId () {
    return Object.keys(this.comment.diagrams)?.[0]
  }

  get diagram () {
    return this.diagramId ? this.diagramModule.diagrams[this.diagramId] : undefined
  }

  get colors (): Record<CommentBodyType, string> {
    return {
      idea: 'white',
      inaccurate: 'error',
      'new-idea': 'white',
      'new-inaccurate': 'error',
      'new-question': 'info',
      question: 'info'
    }
  }

  get icons (): Record<CommentBodyType, string> {
    return {
      idea: '$fas-lightbulb',
      inaccurate: '$fas-thumbs-down',
      'new-idea': '$fas-lightbulb',
      'new-inaccurate': '$fas-thumbs-down',
      'new-question': '$fas-question-circle',
      question: '$fas-question-circle'
    }
  }

  get createdBy () {
    if (this.userModule.user?.id === this.comment.createdById) {
      return 'You'
    } else if (this.currentOrganizationId) {
      return this.organizationModule.organizationUsers[this.currentOrganizationId]?.[this.comment.createdById]?.name || ''
    }
  }

  get caption () {
    if (this.comment.body.type === 'inaccurate') {
      return '+1 inaccuracy'
    }
  }

  get removed () {
    return this.diagramId ? !this.diagramModule.diagrams[this.diagramId] : true
  }

  get resolved () {
    return this.comment.body.status === 'resolved' || this.comment.body.status === 'dismissed'
  }

  get name () {
    if (this.diagram) {
      return this.diagram.name
    }
  }

  get diagramCommentId () {
    return Object.values(this.comment.diagrams)?.[0]?.commentId
  }

  async clickComment () {
    if (this.removed || this.resolved) {
      await this.$replaceQuery({
        accuracy_menu: undefined,
        comment: undefined,
        comment_thread_dialog: this.comment.id,
        comments_list_menu: undefined,
        recommendations_menu: undefined
      })
    } else if (this.diagram && this.currentLandscapeId) {
      await this.$replaceQuery({
        accuracy_menu: undefined,
        comments_list_menu: undefined,
        recommendations_menu: undefined
      })
      await this.$router.push({
        name: this.currentVersionId === 'latest' ? 'editor-diagram' : 'version-diagram',
        query: this.$setQuery({
          comment: this.diagramCommentId,
          comments_hidden: undefined,
          connection: undefined,
          diagram: this.diagram.handleId,
          flow: undefined,
          flow_parent: undefined,
          flow_path: undefined,
          flow_step: undefined,
          model: this.modelModule.objects[this.diagram.modelId]?.handleId,
          object: undefined
        })
      })
    }
  }
}
