import { CancelablePromise, CancelError, Diagram, DiagramAction, DiagramContent, DiagramContentPartial, DiagramContentRequired, DiagramContentsService, DiagramExportPngOptions, DiagramFilter, DiagramGroup, DiagramGroupPartial, DiagramGroupRequired, DiagramGroupsService, DiagramObject, DiagramPartial, DiagramRequired, DiagramsService, DiagramThumbnail, DiagramType, ModelConnectionDiagram, ModelObject, ModelObjectsService, SocketClientDiagramContentsSubscribeBody, SocketClientDiagramGroupsSubscribeBody, SocketClientDiagramsSubscribeBody, TaskProposed } from '@icepanel/platform-api-client'
import { subDays } from 'date-fns'
import Vue from 'vue'
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'

import * as env from '@/helpers/env'
import getFirestoreId from '@/helpers/firestore-id'
import randomId from '@/helpers/random-id'
import * as sort from '@/helpers/sort'
import Status from '@/helpers/status'
import { ServerEvents, Socket } from '@/plugins/socket'

export interface IDiagramModule {
  diagramsCurrent: Record<string, Diagram>
  diagramsCache: Record<string, Diagram> | null
  diagramsCommit: Record<string, number>

  diagramContentsCurrent: Record<string, DiagramContent>
  diagramContentsCache: Record<string, DiagramContent> | null
  diagramContentsCommit: Record<string, number>
  diagramContentStagedId: string | null

  diagramGroupsCurrent: Record<string, DiagramGroup>
  diagramGroupsCache: Record<string, DiagramGroup> | null
  diagramGroupsCommit: Record<string, number>

  diagramsSubscriptionStatus: Status<{ subscriptionId: string, landscapeId: string, versionId: string, unsubscribe: () => void, reconnect: boolean }, { subscriptionId: string, landscapeId: string, versionId: string, unsubscribe: () => void }>
  diagramContentsSubscriptionStatus: Status<{ subscriptionId: string, landscapeId: string, versionId: string, unsubscribe: () => void, reconnect: boolean }, { subscriptionId: string, landscapeId: string, versionId: string, unsubscribe: () => void }>
  diagramGroupsSubscriptionStatus: Status<{ subscriptionId: string, landscapeId: string, versionId: string, unsubscribe: () => void, reconnect: boolean }, { subscriptionId: string, landscapeId: string, versionId: string, unsubscribe: () => void }>

  diagramThumbnails: Record<string, DiagramThumbnail>

  diagramsListStatus: Status
  diagramThumbnailsListStatus: Status<{ landscapeId: string, promise: CancelablePromise<any> }>

  diagramGroupsExpanded: Record<string, boolean>
  diagramsExpandedSections: Record<string, Record<DiagramType, boolean>>

  exploring: boolean
}

const name = 'diagram'

@Module({
  name,
  namespaced: true
})
export class DiagramModule extends VuexModule implements IDiagramModule {
  static namespace = name

  diagramsCurrent: Record<string, Diagram> = {}
  diagramsCache: Record<string, Diagram> | null = null
  diagramsCommit: Record<string, number> = {}

  diagramContentsCurrent: Record<string, DiagramContent> = {}
  diagramContentsCache: Record<string, DiagramContent> | null = null
  diagramContentsCommit: Record<string, number> = {}
  diagramContentStagedId: string | null = null

  diagramGroupsCurrent: Record<string, DiagramGroup> = {}
  diagramGroupsCache: Record<string, DiagramGroup> | null = null
  diagramGroupsCommit: Record<string, number> = {}

  diagramsSubscriptionStatus = new Status<{ subscriptionId: string, landscapeId: string, versionId: string, unsubscribe:() => void, reconnect: boolean }, { subscriptionId: string, landscapeId: string, versionId: string, unsubscribe:() => void }>()
  diagramContentsSubscriptionStatus = new Status<{ subscriptionId: string, landscapeId: string, versionId: string, unsubscribe:() => void, reconnect: boolean }, { subscriptionId: string, landscapeId: string, versionId: string, unsubscribe:() => void }>()
  diagramGroupsSubscriptionStatus = new Status<{ subscriptionId: string, landscapeId: string, versionId: string, unsubscribe:() => void, reconnect: boolean }, { subscriptionId: string, landscapeId: string, versionId: string, unsubscribe:() => void }>()

  diagramThumbnails: Record<string, DiagramThumbnail> = {}

  diagramsListStatus = new Status()
  diagramThumbnailsListStatus = new Status<{ landscapeId: string, promise: CancelablePromise<any> }>()

  diagramGroupsExpanded: Record<string, boolean> = {}
  diagramsExpandedSections: Record<string, Record<DiagramType, boolean>> = {}

  exploring = false

  get generateDiagramId () {
    return () => getFirestoreId('diagram')
  }

  get generateDiagramGroupId () {
    return () => getFirestoreId('diagram-group')
  }

  get generateDiagram () {
    return (landscapeId: string, versionId: string, props: DiagramRequired, id = this.generateDiagramId()): { diagram: Diagram, diagramUpsert: DiagramRequired } => {
      const commit = typeof this.diagramsCommit[id] === 'number' ? this.diagramsCommit[id] + 1 : props.commit || 0
      const defaultHandleId = randomId()
      return {
        diagram: {
          commentCount: 0,
          connectionCount: 0,
          description: '',
          groupId: null,
          handleId: defaultHandleId,
          labels: {},
          objectCount: {
            actor: 0,
            app: 0,
            area: 0,
            box: 0,
            component: 0,
            group: 0,
            store: 0,
            system: 0
          },
          parentId: null,
          status: 'current',
          ...props,
          commit,
          createdAt: new Date().toISOString(),
          createdBy: 'user',
          createdById: this.context.rootState.user.user?.id || '',
          id,
          landscapeId,
          pinned: false,
          stats: {
            edits: {
              all: {
                count: 0,
                users: {}
              },
              day: {},
              month: {},
              week: {}
            },
            views: {
              all: {
                count: 0,
                users: {}
              },
              day: {},
              month: {},
              week: {}
            }
          },
          updatedAt: new Date().toISOString(),
          updatedBy: 'user',
          updatedById: this.context.rootState.user.user?.id || '',
          version: -1,
          versionId
        },
        diagramUpsert: {
          description: '',
          groupId: null,
          handleId: defaultHandleId,
          labels: {},
          ...props,
          commit
        }
      }
    }
  }

  get generateDiagramContent () {
    return (landscapeId: string, versionId: string, diagram: Diagram, props: DiagramContentRequired): { diagramContent: DiagramContent, diagramContentUpsert: DiagramContentRequired } => {
      const commit = typeof this.diagramContentsCommit[diagram.id] === 'number' ? this.diagramContentsCommit[diagram.id] + 1 : props.commit || 0
      const modelObject: ModelObject | undefined = this.context.rootState.model.objectsCurrent[diagram.modelId]

      const areaId = randomId()
      const defaultObjects: Record<string, DiagramObject> = {}
      if (diagram.type !== 'context-diagram' && modelObject && modelObject.type !== 'root') {
        defaultObjects[areaId] = {
          height: 512,
          id: areaId,
          modelId: diagram.modelId,
          shape: 'area',
          type: modelObject.type,
          width: 768,
          x: 128,
          y: 64
        }
      }

      let tasksProposed: TaskProposed[] = []
      if (props.tasksProposed) {
        tasksProposed = props.tasksProposed.map(o => ({
          proposedAt: new Date().toISOString(),
          proposedBy: 'user',
          proposedById: this.context.rootState.user.user?.id || '',
          task: o
        }))
      }

      return {
        diagramContent: {
          comments: {},
          connections: {},
          groupId: diagram.groupId,
          handleId: diagram.handleId,
          modelId: diagram.modelId,
          name: '',
          objects: defaultObjects,
          status: 'current',
          type: diagram.type,
          ...props,
          commit,
          createdAt: new Date().toISOString(),
          createdBy: 'user',
          createdById: this.context.rootState.user.user?.id || '',
          id: diagram.id,
          landscapeId,
          tasksProposed,
          updatedAt: new Date().toISOString(),
          updatedBy: 'user',
          updatedById: this.context.rootState.user.user?.id || '',
          version: -1,
          versionId
        },
        diagramContentUpsert: {
          connections: {},
          objects: defaultObjects,
          ...props,
          commit
        }
      }
    }
  }

  get generateDiagramGroup () {
    return (landscapeId: string, versionId: string, props: DiagramGroupRequired, id = this.generateDiagramGroupId()): { diagramGroup: DiagramGroup, diagramGroupUpsert: DiagramGroupRequired } => {
      const commit = typeof this.diagramGroupsCommit[id] === 'number' ? this.diagramGroupsCommit[id] + 1 : props.commit || 0
      return {
        diagramGroup: {
          handleId: randomId(),
          index: 0,
          labels: {},
          ...props,
          commit,
          createdAt: new Date().toISOString(),
          createdBy: 'user',
          createdById: this.context.rootState.user.user?.id || '',
          id,
          landscapeId,
          updatedAt: new Date().toISOString(),
          updatedBy: 'user',
          updatedById: this.context.rootState.user.user?.id || '',
          version: -1,
          versionId
        },
        diagramGroupUpsert: {
          handleId: randomId(),
          labels: {},
          ...props,
          commit
        }
      }
    }
  }

  get generateDiagramCommit () {
    return (id: string, props: Omit<DiagramPartial, 'commit'>): { diagram: Diagram, diagramUpdate: DiagramPartial } => {
      const diagram = structuredClone(this.diagrams[id])
      if (diagram) {
        const commit = diagram.commit + 1
        diagram.pinnedAt = props.pinned ? new Date().toISOString() : undefined
        diagram.updatedAt = new Date().toISOString()
        diagram.updatedBy = 'user'
        diagram.updatedById = this.context.rootState.user.user?.id || ''

        return {
          diagram: {
            ...diagram,
            ...props,
            commit
          },
          diagramUpdate: {
            ...props,
            commit
          }
        }
      } else {
        throw new Error(`Could not find diagram ${id}`)
      }
    }
  }

  get generateDiagramContentCommit () {
    return (id: string, props: Omit<DiagramContentPartial, 'commit'>, existing?: DiagramContent, clone = true): { diagramContent: DiagramContent, diagramContentUpdate: DiagramContentPartial, modelConnectionDiagramAdd: Record<string, ModelConnectionDiagram>, modelConnectionDiagramRemove: Record<string, string> } => {
      const defaultDiagramContent = id === this.diagramContentStagedId ? this.diagramContentsCurrent[id] : this.diagramContents[id]
      let diagramContent = existing || defaultDiagramContent
      if (clone) {
        diagramContent = structuredClone(diagramContent)
      }
      if (diagramContent) {
        const commit = diagramContent.commit + 1
        diagramContent.updatedAt = new Date().toISOString()
        diagramContent.updatedBy = 'user'
        diagramContent.updatedById = this.context.rootState.user.user?.id || ''

        const modelConnectionDiagramAdd: Record<string, ModelConnectionDiagram> = {}
        const modelConnectionDiagramRemove: Record<string, string> = {}

        if (props.objects?.$replace) {
          diagramContent.objects = props.objects.$replace
        } else {
          if (props.objects?.$add) {
            const connectionConflictId = Object.keys(props.objects.$add).find(o => diagramContent.objects[o])
            if (connectionConflictId) {
              throw new Error(`Object ${connectionConflictId} already exists`)
            }

            Object.values(props.objects.$add).forEach(o => {
              diagramContent.objects[o.id] = o
            })
          }

          if (props.objects?.$update) {
            const connectionConflictId = Object.keys(props.objects.$update).find(o => !diagramContent.objects[o])
            if (connectionConflictId) {
              throw new Error(`Object ${connectionConflictId} does not exist`)
            }

            Object.entries(props.objects.$update).forEach(([id, o]) => {
              Object.assign(diagramContent.objects[id], o)
            })
          }

          if (props.objects?.$remove) {
            const connectionConflictId = props.objects.$remove.find(o => !diagramContent.objects[o])
            if (connectionConflictId) {
              throw new Error(`Object ${connectionConflictId} does not exist`)
            }

            props.objects.$remove.forEach(o => {
              delete diagramContent.objects[o]
            })
          }
        }

        if (props.connections?.$replace) {
          diagramContent.connections = props.connections.$replace
        } else {
          if (props.connections?.$add) {
            const connectionConflictId = Object.keys(props.connections.$add).find(o => diagramContent.connections[o])
            if (connectionConflictId) {
              throw new Error(`Connection ${connectionConflictId} already exists`)
            }

            Object.values(props.connections.$add).forEach(o => {
              const originModelId = o.originId ? diagramContent.objects[o.originId].modelId : undefined
              const targetModelId = o.targetId ? diagramContent.objects[o.targetId].modelId : undefined

              if (o.modelId && originModelId && targetModelId) {
                modelConnectionDiagramAdd[o.modelId] = {
                  connectionId: o.id,
                  id: diagramContent.id,
                  originModelId,
                  targetModelId
                }
              }

              diagramContent.connections[o.id] = o
            })
          }

          if (props.connections?.$update) {
            const connectionConflictId = Object.keys(props.connections.$update).find(o => !diagramContent.connections[o])
            if (connectionConflictId) {
              throw new Error(`Connection ${connectionConflictId} does not exist`)
            }

            Object.entries(props.connections.$update).forEach(([connectionId, o]) => {
              Object.assign(diagramContent.connections[connectionId], o)

              const connection = diagramContent.connections[connectionId]
              const originModelId = connection?.originId ? diagramContent.objects[connection.originId].modelId : undefined
              const targetModelId = connection?.targetId ? diagramContent.objects[connection.targetId].modelId : undefined

              if (o.modelId && originModelId && targetModelId) {
                modelConnectionDiagramAdd[o.modelId] = {
                  connectionId,
                  id: diagramContent.id,
                  originModelId,
                  targetModelId
                }
              } else if (connection?.modelId && o.modelId === null) {
                modelConnectionDiagramRemove[connection.modelId] = diagramContent.id
              }
            })
          }

          if (props.connections?.$remove) {
            const connectionConflictId = props.connections.$remove.find(o => !diagramContent.connections[o])
            if (connectionConflictId) {
              throw new Error(`Connection ${connectionConflictId} does not exist`)
            }

            props.connections?.$remove.forEach(o => {
              const connection = diagramContent.connections[o]
              if (connection?.modelId) {
                modelConnectionDiagramRemove[connection.modelId] = diagramContent.id
              }

              delete diagramContent.connections[o]
            })
          }
        }

        if (props.comments?.$replace) {
          diagramContent.comments = props.comments.$replace
        } else {
          if (props.comments?.$add) {
            const commentConflictId = Object.keys(props.comments.$add).find(o => diagramContent.comments[o])
            if (commentConflictId) {
              throw new Error(`Comment ${commentConflictId} already exists`)
            }

            Object.values(props.comments.$add).forEach(o => {
              diagramContent.comments[o.id] = o
            })
          }

          if (props.comments?.$update) {
            const commentConflictId = Object.keys(props.comments.$update).find(o => !diagramContent.comments[o])
            if (commentConflictId) {
              throw new Error(`Comment ${commentConflictId} does not exist`)
            }

            Object.entries(props.comments.$update).forEach(([commentId, o]) => {
              Object.assign(diagramContent.comments[commentId], o)
            })
          }

          if (props.comments?.$remove) {
            const commentConflictId = props.comments.$remove.find(o => !diagramContent.comments[o])
            if (commentConflictId) {
              throw new Error(`Comment ${commentConflictId} does not exist`)
            }

            props.comments?.$remove.forEach(o => {
              delete diagramContent.comments[o]
            })
          }
        }

        if (props.tasksProposed?.$replace) {
          props.tasksProposed.$replace.forEach(o => {
            if ('props' in o && 'commit' in o.props) {
              delete o.props.commit
            }
          })
          diagramContent.tasksProposed = props.tasksProposed.$replace.map(o => ({
            proposedAt: new Date().toISOString(),
            proposedBy: 'user' as const,
            proposedById: this.context.rootState.user.user?.id || '',
            task: o
          }))
        } else if (props.tasksProposed?.$append) {
          props.tasksProposed.$append.forEach(o => {
            if ('props' in o && 'commit' in o.props) {
              delete o.props.commit
            }
          })
          diagramContent.tasksProposed.push(...props.tasksProposed.$append.map(o => ({
            proposedAt: new Date().toISOString(),
            proposedBy: 'user' as const,
            proposedById: this.context.rootState.user.user?.id || '',
            task: o
          })))
        }

        Object.assign(diagramContent, props, {
          comments: diagramContent.comments,
          commit,
          connections: diagramContent.connections,
          objects: diagramContent.objects,
          tasksProposed: diagramContent.tasksProposed
        })

        return {
          diagramContent,
          diagramContentUpdate: {
            ...props,
            commit
          },
          modelConnectionDiagramAdd,
          modelConnectionDiagramRemove
        }
      } else {
        throw new Error(`Could not find diagram content ${id}`)
      }
    }
  }

  get generateDiagramGroupCommit () {
    return (id: string, props: Omit<DiagramGroupPartial, 'commit'>): { diagramGroup: DiagramGroup, diagramGroupUpdate: DiagramGroupPartial } => {
      const diagramGroup = structuredClone(this.diagramGroups[id])
      if (diagramGroup) {
        const commit = diagramGroup.commit + 1
        diagramGroup.updatedAt = new Date().toISOString()
        diagramGroup.updatedBy = 'user'
        diagramGroup.updatedById = this.context.rootState.user.user?.id || ''

        return {
          diagramGroup: {
            ...diagramGroup,
            ...props,
            commit
          },
          diagramGroupUpdate: {
            ...props,
            commit
          }
        }
      } else {
        throw new Error(`Could not find diagram group ${id}`)
      }
    }
  }

  get diagrams () {
    return this.diagramsCache || this.diagramsCurrent
  }

  get diagramContents () {
    if (this.diagramContentsCache) {
      return this.diagramContentsCache
    } else {
      return this.diagramContentsCurrent
    }
  }

  get diagramGroups () {
    return this.diagramGroupsCache || this.diagramGroupsCurrent
  }

  get diagramContentStaged () {
    return this.diagramContentStagedId ? this.diagramContentsCurrent[this.diagramContentStagedId] : null
  }

  get socket (): Socket {
    return this.context.rootState.socket.socket
  }

  @Mutation
  setDiagrams (diagrams: Diagram[] | Record<string, Diagram>) {
    this.diagramsCurrent = {}
    this.diagramsCommit = {}

    Object
      .values(diagrams)
      .forEach(o => {
        Vue.set(this.diagramsCurrent, o.id, o)
        Vue.set(this.diagramsCommit, o.id, o.commit)
      })
  }

  @Mutation
  setDiagramContents (diagramContents: DiagramContent[] | Record<string, DiagramContent>) {
    this.diagramContentsCurrent = {}
    this.diagramContentsCommit = {}

    Object
      .values(diagramContents)
      .forEach(o => {
        Vue.set(this.diagramContentsCurrent, o.id, o)
        Vue.set(this.diagramContentsCommit, o.id, o.commit)
      })
  }

  @Mutation
  setDiagramGroups (diagramGroups: DiagramGroup[] | Record<string, DiagramGroup>) {
    this.diagramGroupsCurrent = {}
    this.diagramGroupsCommit = {}

    Object
      .values(diagramGroups)
      .forEach(o => {
        Vue.set(this.diagramGroupsCurrent, o.id, o)
        Vue.set(this.diagramGroupsCommit, o.id, o.commit)
      })
  }

  @Mutation
  setDiagramsCache (diagramsCache: Record<string, Diagram> | null) {
    this.diagramsCache = diagramsCache
  }

  @Mutation
  setDiagramContentsCache (diagramContentsCache: Record<string, DiagramContent> | null) {
    this.diagramContentsCache = diagramContentsCache
  }

  @Mutation
  setDiagramGroupsCache (diagramGroupsCache: Record<string, DiagramGroup> | null) {
    this.diagramGroupsCache = diagramGroupsCache
  }

  @Mutation
  setDiagramContentStagedId (diagramContentStagedId: string | null) {
    this.diagramContentStagedId = diagramContentStagedId
  }

  @Mutation
  setDiagram (diagram: Diagram) {
    Vue.set(this.diagramsCurrent, diagram.id, diagram)
    Vue.set(this.diagramGroupsCommit, diagram.id, diagram.commit)
  }

  @Mutation
  setDiagramContent (diagramContent: DiagramContent) {
    Vue.set(this.diagramContentsCurrent, diagramContent.id, diagramContent)
    Vue.set(this.diagramContentsCommit, diagramContent.id, diagramContent.commit)
  }

  @Mutation
  setDiagramGroup (diagramGroup: DiagramGroup) {
    Vue.set(this.diagramGroupsCurrent, diagramGroup.id, diagramGroup)
    Vue.set(this.diagramGroupsCommit, diagramGroup.id, diagramGroup.commit)
  }

  @Mutation
  setDiagramVersion (diagram: Diagram) {
    if (
      this.diagramsCommit[diagram.id] === undefined ||
      diagram.commit > this.diagramsCommit[diagram.id] ||
      (diagram.commit === this.diagramsCommit[diagram.id] && this.diagramsCurrent[diagram.id] && diagram.version > this.diagramsCurrent[diagram.id].version)
    ) {
      Vue.set(this.diagramsCurrent, diagram.id, diagram)
      Vue.set(this.diagramsCommit, diagram.id, diagram.commit)
    }
  }

  @Mutation
  setDiagramContentVersion (diagramContent: DiagramContent) {
    if (
      this.diagramContentsCommit[diagramContent.id] === undefined ||
      diagramContent.commit > this.diagramContentsCommit[diagramContent.id] ||
      (diagramContent.commit === this.diagramContentsCommit[diagramContent.id] && this.diagramContentsCurrent[diagramContent.id] && diagramContent.version > this.diagramContentsCurrent[diagramContent.id].version)
    ) {
      Vue.set(this.diagramContentsCurrent, diagramContent.id, diagramContent)
      Vue.set(this.diagramContentsCommit, diagramContent.id, diagramContent.commit)
    }
  }

  @Mutation
  setDiagramGroupVersion (diagramGroup: DiagramGroup) {
    if (
      this.diagramGroupsCommit[diagramGroup.id] === undefined ||
      diagramGroup.commit > this.diagramGroupsCommit[diagramGroup.id] ||
      (diagramGroup.commit === this.diagramGroupsCommit[diagramGroup.id] && this.diagramGroupsCurrent[diagramGroup.id] && diagramGroup.version > this.diagramGroupsCurrent[diagramGroup.id].version)
    ) {
      Vue.set(this.diagramGroupsCurrent, diagramGroup.id, diagramGroup)
      Vue.set(this.diagramGroupsCommit, diagramGroup.id, diagramGroup.commit)
    }
  }

  @Mutation
  removeDiagram (diagram: string | Pick<Diagram, 'id' | 'commit'>) {
    if (typeof diagram === 'string') {
      Vue.delete(this.diagramsCurrent, diagram)
    } else if (diagram.commit >= this.diagramsCommit[diagram.id]) {
      Vue.delete(this.diagramsCurrent, diagram.id)
      this.diagramsCommit[diagram.id] = diagram.commit
    }
  }

  @Mutation
  removeDiagramContent (diagramContent: string | Pick<DiagramContent, 'id' | 'commit'>) {
    if (typeof diagramContent === 'string') {
      Vue.delete(this.diagramContentsCurrent, diagramContent)
    } else if (diagramContent.commit >= this.diagramContentsCommit[diagramContent.id]) {
      Vue.delete(this.diagramContentsCurrent, diagramContent.id)
      this.diagramContentsCommit[diagramContent.id] = diagramContent.commit
    }
  }

  @Mutation
  removeDiagramGroup (diagramGroup: string | Pick<DiagramGroup, 'id' | 'commit'>) {
    if (typeof diagramGroup === 'string') {
      Vue.delete(this.diagramGroupsCurrent, diagramGroup)
    } else if (diagramGroup.commit >= this.diagramGroupsCommit[diagramGroup.id]) {
      Vue.delete(this.diagramGroupsCurrent, diagramGroup.id)
      this.diagramGroupsCommit[diagramGroup.id] = diagramGroup.commit
    }
  }

  @Mutation
  setExploring (exploring: boolean) {
    this.exploring = exploring
  }

  @Mutation
  resetDiagrams () {
    this.diagramsCurrent = {}
    this.diagramsCache = null
    this.diagramsCommit = {}
  }

  @Mutation
  resetDiagramContents () {
    this.diagramContentsCurrent = {}
    this.diagramContentsCache = null
    this.diagramContentsCommit = {}
  }

  @Mutation
  resetDiagramGroups () {
    this.diagramGroupsCurrent = {}
    this.diagramGroupsCache = null
    this.diagramGroupsCommit = {}
  }

  @Mutation
  setDiagramThumbnails (thumbnails: Record<string, DiagramThumbnail>) {
    this.diagramThumbnails = {
      ...this.diagramThumbnails,
      ...thumbnails
    }
  }

  @Mutation
  resetDiagramThumbnails () {
    this.diagramThumbnails = {}
    this.diagramThumbnailsListStatus.set(Status.idle())
  }

  @Mutation
  setDiagramGroupsExpanded (expanded: Record<string, boolean>) {
    this.diagramGroupsExpanded = {
      ...this.diagramGroupsExpanded,
      ...expanded
    }
  }

  @Mutation
  setDiagramsExpandedSections (expandedSections: Record<string, Record<DiagramType, boolean>>) {
    this.diagramsExpandedSections = {
      ...this.diagramsExpandedSections,
      ...expandedSections
    }
  }

  @Mutation
  setDiagramsListStatus (...params: Parameters<typeof this.diagramsListStatus.set>) {
    this.diagramsListStatus.set(...params)
  }

  @Mutation
  setDiagramThumbnailsListStatus (...params: Parameters<typeof this.diagramThumbnailsListStatus.set>) {
    this.diagramThumbnailsListStatus.loadingInfo.promise?.cancel()
    this.diagramThumbnailsListStatus.set(...params)
  }

  @Mutation
  setDiagramsSubscriptionStatus (...params: Parameters<typeof this.diagramsSubscriptionStatus.set>) {
    this.diagramsSubscriptionStatus.set(...params)
  }

  @Mutation
  setDiagramContentsSubscriptionStatus (...params: Parameters<typeof this.diagramContentsSubscriptionStatus.set>) {
    this.diagramContentsSubscriptionStatus.set(...params)
  }

  @Mutation
  setDiagramGroupsSubscriptionStatus (...params: Parameters<typeof this.diagramGroupsSubscriptionStatus.set>) {
    this.diagramGroupsSubscriptionStatus.set(...params)
  }

  @Mutation
  diagramsUnsubscribe () {
    this.diagramsSubscriptionStatus.loadingInfo?.unsubscribe?.()
    this.diagramsSubscriptionStatus.successInfo?.unsubscribe?.()
    this.diagramsSubscriptionStatus.set(Status.idle())
  }

  @Mutation
  diagramContentsUnsubscribe () {
    this.diagramContentsSubscriptionStatus.loadingInfo?.unsubscribe?.()
    this.diagramContentsSubscriptionStatus.successInfo?.unsubscribe?.()
    this.diagramContentsSubscriptionStatus.set(Status.idle())
  }

  @Mutation
  diagramGroupsUnsubscribe () {
    this.diagramGroupsSubscriptionStatus.loadingInfo?.unsubscribe?.()
    this.diagramGroupsSubscriptionStatus.successInfo?.unsubscribe?.()
    this.diagramGroupsSubscriptionStatus.set(Status.idle())
  }

  @Action({ rawError: true })
  async diagramsSubscribe (body: SocketClientDiagramsSubscribeBody & { reconnect: boolean }) {
    this.diagramsSubscriptionStatus.loadingInfo?.unsubscribe?.()
    this.diagramsSubscriptionStatus.successInfo?.unsubscribe?.()

    this.context.commit('setDiagramsSubscriptionStatus', Status.loading({
      landscapeId: body.landscapeId,
      reconnect: body.reconnect,
      versionId: body.versionId
    }))

    const initialValue: Diagram[] = []

    await new Promise<void>((resolve, reject) => {
      this.socket.emit('diagrams-subscribe', {
        initialValue: true,
        landscapeId: body.landscapeId,
        versionId: body.versionId
      }, (err, reply) => {
        if (reply) {
          const diagramInitialValueListener: ServerEvents['diagram-initial-value'] = ({ diagrams, finalValue, subscriptionId }) => {
            if (reply.subscriptionId === subscriptionId) {
              initialValue.push(...diagrams)

              if (finalValue) {
                this.context.commit('setDiagrams', initialValue)

                this.context.commit('setDiagramsSubscriptionStatus', Status.success({
                  landscapeId: body.landscapeId,
                  subscriptionId: reply.subscriptionId,
                  unsubscribe,
                  versionId: body.versionId
                }))

                this.context.dispatch('socket/checkSocket', undefined, { root: true })
              }
            }
          }
          const diagramAddedListener: ServerEvents['diagram-added'] = ({ diagram, subscriptionId }) => {
            if (reply.subscriptionId === subscriptionId) {
              this.context.commit('setDiagramVersion', diagram)
            }
            if (diagram.createdById && diagram.createdById !== this.context.rootState.user.user?.id) {
              this.context.commit('editor/resetTaskLists', undefined, { root: true })
            }
          }
          const diagramModifiedListener: ServerEvents['diagram-modified'] = ({ diagram, subscriptionId }) => {
            if (reply.subscriptionId === subscriptionId) {
              this.context.commit('setDiagramVersion', diagram)
            }
            if (diagram.updatedById && diagram.updatedById !== this.context.rootState.user.user?.id) {
              this.context.commit('editor/resetTaskLists', undefined, { root: true })
            }
          }
          const diagramRemovedListener: ServerEvents['diagram-removed'] = ({ diagram, subscriptionId }) => {
            if (reply.subscriptionId === subscriptionId) {
              this.context.commit('removeDiagram', diagram)
            }
            if (diagram.deletedById && diagram.deletedById !== this.context.rootState.user.user?.id) {
              this.context.commit('editor/resetTaskLists', undefined, { root: true })
            }
          }

          const socketId = this.socket.id

          const unsubscribe = () => {
            this.socket.off('diagram-initial-value', diagramInitialValueListener)
            this.socket.off('diagram-added', diagramAddedListener)
            this.socket.off('diagram-modified', diagramModifiedListener)
            this.socket.off('diagram-removed', diagramRemovedListener)

            if (this.socket.id === socketId && this.socket.connected) {
              this.socket.emit('diagrams-unsubscribe', {
                subscriptionId: reply.subscriptionId
              }, err => {
                if (err) {
                  console.error('diagrams unsubscribe error', err)
                }
              })
            }
          }

          this.socket.on('diagram-initial-value', diagramInitialValueListener)
          this.socket.on('diagram-added', diagramAddedListener)
          this.socket.on('diagram-modified', diagramModifiedListener)
          this.socket.on('diagram-removed', diagramRemovedListener)

          this.context.commit('setDiagramsSubscriptionStatus', Status.loading({
            landscapeId: body.landscapeId,
            reconnect: body.reconnect,
            subscriptionId: reply.subscriptionId,
            unsubscribe,
            versionId: body.versionId
          }))

          resolve()
        } else {
          const error = err || 'Diagram subscription not provided'
          this.context.commit('setDiagramsSubscriptionStatus', Status.error(error))
          reject(new Error(error))
        }
      })
    })
  }

  @Action({ rawError: true })
  async diagramContentsSubscribe (body: SocketClientDiagramContentsSubscribeBody & { reconnect: boolean }) {
    this.diagramContentsSubscriptionStatus.loadingInfo?.unsubscribe?.()
    this.diagramContentsSubscriptionStatus.successInfo?.unsubscribe?.()

    this.context.commit('setDiagramContentsSubscriptionStatus', Status.loading({
      landscapeId: body.landscapeId,
      reconnect: body.reconnect,
      versionId: body.versionId
    }))

    const initialValue: DiagramContent[] = []

    await new Promise<void>((resolve, reject) => {
      this.socket.emit('diagram-contents-subscribe', {
        filter: {
          afterViewedAtDate: subDays(new Date(), 5).toISOString()
        },
        initialValue: true,
        landscapeId: body.landscapeId,
        versionId: body.versionId
      }, (err, reply) => {
        if (reply) {
          const diagramContentInitialValueListener: ServerEvents['diagram-content-initial-value'] = ({ diagramContents, finalValue, subscriptionId }) => {
            if (reply.subscriptionId === subscriptionId) {
              initialValue.push(...diagramContents)

              if (finalValue) {
                this.context.commit('setDiagramContents', initialValue)

                this.context.commit('setDiagramContentsSubscriptionStatus', Status.success({
                  landscapeId: body.landscapeId,
                  subscriptionId: reply.subscriptionId,
                  unsubscribe,
                  versionId: body.versionId
                }))

                this.context.dispatch('socket/checkSocket', undefined, { root: true })
              }
            }
          }
          const diagramContentAddedListener: ServerEvents['diagram-content-added'] = ({ diagramContent, subscriptionId }) => {
            if (reply.subscriptionId === subscriptionId) {
              this.context.commit('setDiagramContentVersion', diagramContent)
            }
            if (diagramContent.createdById && diagramContent.createdById !== this.context.rootState.user.user?.id) {
              this.context.commit('editor/resetTaskLists', undefined, { root: true })
            }
          }
          const diagramContentModifiedListener: ServerEvents['diagram-content-modified'] = ({ diagramContent, subscriptionId }) => {
            if (reply.subscriptionId === subscriptionId) {
              this.context.commit('setDiagramContentVersion', diagramContent)
            }
            if (diagramContent.updatedById && diagramContent.updatedById !== this.context.rootState.user.user?.id) {
              this.context.commit('editor/resetTaskLists', undefined, { root: true })
            }
          }
          const diagramContentRemovedListener: ServerEvents['diagram-content-removed'] = ({ diagramContent, subscriptionId }) => {
            if (reply.subscriptionId === subscriptionId) {
              this.context.commit('removeDiagramContent', diagramContent)
            }
            if (diagramContent.deletedById && diagramContent.deletedById !== this.context.rootState.user.user?.id) {
              this.context.commit('editor/resetTaskLists', undefined, { root: true })
            }
          }

          const socketId = this.socket.id

          const unsubscribe = () => {
            this.socket.off('diagram-content-initial-value', diagramContentInitialValueListener)
            this.socket.off('diagram-content-added', diagramContentAddedListener)
            this.socket.off('diagram-content-modified', diagramContentModifiedListener)
            this.socket.off('diagram-content-removed', diagramContentRemovedListener)

            if (this.socket.id === socketId && this.socket.connected) {
              this.socket.emit('diagram-contents-unsubscribe', {
                subscriptionId: reply.subscriptionId
              }, err => {
                if (err) {
                  console.error('diagram content unsubscribe error', err)
                }
              })
            }
          }

          this.socket.on('diagram-content-initial-value', diagramContentInitialValueListener)
          this.socket.on('diagram-content-added', diagramContentAddedListener)
          this.socket.on('diagram-content-modified', diagramContentModifiedListener)
          this.socket.on('diagram-content-removed', diagramContentRemovedListener)

          this.context.commit('setDiagramContentsSubscriptionStatus', Status.loading({
            landscapeId: body.landscapeId,
            reconnect: body.reconnect,
            subscriptionId: reply.subscriptionId,
            unsubscribe,
            versionId: body.versionId
          }))

          resolve()
        } else {
          const error = err || 'Diagram content subscription not provided'
          this.context.commit('setDiagramContentsSubscriptionStatus', Status.error(error))
          reject(new Error(error))
        }
      })
    })
  }

  @Action({ rawError: true })
  async diagramGroupsSubscribe (body: SocketClientDiagramGroupsSubscribeBody & { reconnect: boolean }) {
    this.diagramGroupsSubscriptionStatus.loadingInfo?.unsubscribe?.()
    this.diagramGroupsSubscriptionStatus.successInfo?.unsubscribe?.()

    this.context.commit('setDiagramGroupsSubscriptionStatus', Status.loading({
      landscapeId: body.landscapeId,
      reconnect: body.reconnect,
      versionId: body.versionId
    }))

    const initialValue: DiagramGroup[] = []

    await new Promise<void>((resolve, reject) => {
      this.socket.emit('diagram-groups-subscribe', {
        landscapeId: body.landscapeId,
        versionId: body.versionId
      }, (err, reply) => {
        if (reply) {
          const diagramGroupInitialValueListener: ServerEvents['diagram-group-initial-value'] = ({ diagramGroups, finalValue, subscriptionId }) => {
            if (reply.subscriptionId === subscriptionId) {
              initialValue.push(...diagramGroups)

              if (finalValue) {
                this.context.commit('setDiagramGroups', initialValue)

                this.context.commit('setDiagramGroupsSubscriptionStatus', Status.success({
                  landscapeId: body.landscapeId,
                  subscriptionId: reply.subscriptionId,
                  unsubscribe,
                  versionId: body.versionId
                }))

                this.context.dispatch('socket/checkSocket', undefined, { root: true })
              }
            }
          }
          const diagramGroupAddedListener: ServerEvents['diagram-group-added'] = ({ diagramGroup, subscriptionId }) => {
            if (reply.subscriptionId === subscriptionId) {
              this.context.commit('setDiagramGroupVersion', diagramGroup)
            }
            if (diagramGroup.createdById && diagramGroup.createdById !== this.context.rootState.user.user?.id) {
              this.context.commit('editor/resetTaskLists', undefined, { root: true })
            }
          }
          const diagramGroupModifiedListener: ServerEvents['diagram-group-modified'] = ({ diagramGroup, subscriptionId }) => {
            if (reply.subscriptionId === subscriptionId) {
              this.context.commit('setDiagramGroupVersion', diagramGroup)
            }
            if (diagramGroup.updatedById && diagramGroup.updatedById !== this.context.rootState.user.user?.id) {
              this.context.commit('editor/resetTaskLists', undefined, { root: true })
            }
          }
          const diagramGroupRemovedListener: ServerEvents['diagram-group-removed'] = ({ diagramGroup, subscriptionId }) => {
            if (reply.subscriptionId === subscriptionId) {
              this.context.commit('removeDiagramGroup', diagramGroup)
            }
            if (diagramGroup.deletedById && diagramGroup.deletedById !== this.context.rootState.user.user?.id) {
              this.context.commit('editor/resetTaskLists', undefined, { root: true })
            }
          }

          const socketId = this.socket.id

          const unsubscribe = () => {
            this.socket.off('diagram-group-initial-value', diagramGroupInitialValueListener)
            this.socket.off('diagram-group-added', diagramGroupAddedListener)
            this.socket.off('diagram-group-modified', diagramGroupModifiedListener)
            this.socket.off('diagram-group-removed', diagramGroupRemovedListener)

            if (this.socket.id === socketId && this.socket.connected) {
              this.socket.emit('diagram-groups-unsubscribe', {
                subscriptionId: reply.subscriptionId
              }, err => {
                if (err) {
                  console.error('diagrams unsubscribe error', err)
                }
              })
            }
          }

          this.socket.on('diagram-group-initial-value', diagramGroupInitialValueListener)
          this.socket.on('diagram-group-added', diagramGroupAddedListener)
          this.socket.on('diagram-group-modified', diagramGroupModifiedListener)
          this.socket.on('diagram-group-removed', diagramGroupRemovedListener)

          this.context.commit('setDiagramGroupsSubscriptionStatus', Status.loading({
            landscapeId: body.landscapeId,
            reconnect: body.reconnect,
            subscriptionId: reply.subscriptionId,
            unsubscribe,
            versionId: body.versionId
          }))

          resolve()
        } else {
          const error = err || 'Diagram group subscription not provided'
          this.context.commit('setDiagramGroupsSubscriptionStatus', Status.error(error))
          reject(new Error(error))
        }
      })
    })
  }

  @Action({ rawError: true })
  async diagramsList ({ landscapeId, versionId, filter }: { landscapeId: string, versionId: string, filter?: DiagramFilter }) {
    try {
      this.context.commit('setDiagramsListStatus', Status.loading())

      const authorization = await this.context.dispatch('user/getAuthorizationHeader', undefined, { root: true })
      const { diagrams } = await DiagramsService.diagramsList(authorization, landscapeId, versionId, filter)
      this.context.commit('setDiagrams', diagrams)

      this.context.commit('setDiagramsListStatus', Status.success())

      return diagrams
    } catch (err: any) {
      const message = err.body?.message || err.message
      this.context.commit('setDiagramsListStatus', Status.error(message))
      this.context.commit('alert/pushAlert', { color: 'error', message }, { root: true })
      throw err
    }
  }

  @Action({ rawError: true })
  async diagramCreate ({ landscapeId, versionId, props, updateViewedAt }: { landscapeId: string, versionId: string, props: DiagramRequired & DiagramContentRequired, updateViewedAt?: boolean }) {
    const authorization = await this.context.dispatch('user/getAuthorizationHeader', undefined, { root: true })
    const { diagram, diagramContent } = await DiagramsService.diagramCreate(authorization, landscapeId, versionId, props, updateViewedAt)
    this.context.commit('setDiagramVersion', diagram)
    this.context.commit('setDiagramContentVersion', diagramContent)
    return diagram
  }

  @Action({ rawError: true })
  async diagramUpsert ({ landscapeId, versionId, diagramId, props, updateViewedAt }: { landscapeId: string, versionId: string, diagramId: string, props: DiagramRequired & DiagramContentRequired, updateViewedAt?: boolean }) {
    const authorization = await this.context.dispatch('user/getAuthorizationHeader', undefined, { root: true })
    const { diagram, diagramContent } = await DiagramsService.diagramUpsert(authorization, landscapeId, versionId, diagramId, props, updateViewedAt)
    this.context.commit('setDiagramVersion', diagram)
    if (diagramContent) {
      this.context.commit('setDiagramContentVersion', diagramContent)
    }
    return diagram
  }

  @Action({ rawError: true })
  async diagramUpdate ({ landscapeId, versionId, diagramId, props, updateViewedAt }: { landscapeId: string, versionId: string, diagramId: string, props: DiagramPartial & DiagramContentPartial, updateViewedAt?: boolean }) {
    const authorization = await this.context.dispatch('user/getAuthorizationHeader', undefined, { root: true })
    const { diagram, diagramContent } = await DiagramsService.diagramUpdate(authorization, landscapeId, versionId, diagramId, props, updateViewedAt)
    this.context.commit('setDiagramVersion', diagram)
    if (diagramContent) {
      this.context.commit('setDiagramContentVersion', diagramContent)
    }
    return diagram
  }

  @Action({ rawError: true })
  async diagramDelete ({ landscapeId, versionId, diagramId }: { landscapeId: string, versionId: string, diagramId: string }) {
    const authorization = await this.context.dispatch('user/getAuthorizationHeader', undefined, { root: true })
    const { commit } = await DiagramsService.diagramDelete(authorization, landscapeId, versionId, diagramId)
    this.context.commit('removeDiagram', { commit, id: diagramId })
    this.context.commit('removeDiagramContent', { commit, id: diagramId })
  }

  @Action({ rawError: true })
  async diagramContentFind ({ landscapeId, versionId, diagramId }: { landscapeId: string, versionId: string, diagramId: string }) {
    const authorization = await this.context.dispatch('user/getAuthorizationHeader', undefined, { root: true })
    const { diagramContent } = await DiagramContentsService.diagramContentFind(authorization, landscapeId, versionId, diagramId)
    this.context.commit('setDiagramContentVersion', diagramContent)

    return diagramContent
  }

  @Action({ rawError: true })
  async diagramContentReplace ({ landscapeId, versionId, diagramId, props }: { landscapeId: string, versionId: string, diagramId: string, props: DiagramContentRequired }) {
    const authorization = await this.context.dispatch('user/getAuthorizationHeader', undefined, { root: true })
    const { diagramContent } = await DiagramContentsService.diagramContentReplace(authorization, landscapeId, versionId, diagramId, props)
    this.context.commit('setDiagramContentVersion', diagramContent)
  }

  @Action({ rawError: true })
  async diagramContentUpdate ({ landscapeId, versionId, diagramId, props }: { landscapeId: string, versionId: string, diagramId: string, props: DiagramContentPartial }) {
    const authorization = await this.context.dispatch('user/getAuthorizationHeader', undefined, { root: true })
    const { diagramContent } = await DiagramContentsService.diagramContentUpdate(authorization, landscapeId, versionId, diagramId, props)
    this.context.commit('setDiagramContentVersion', diagramContent)
  }

  @Action({ rawError: true })
  async diagramGroupCreate ({ landscapeId, versionId, props }: { landscapeId: string, versionId: string, props: DiagramGroupRequired }) {
    const authorization = await this.context.dispatch('user/getAuthorizationHeader', undefined, { root: true })
    const { diagramGroup } = await DiagramGroupsService.diagramGroupCreate(authorization, landscapeId, versionId, props)
    this.context.commit('setDiagramGroupVersion', diagramGroup)
  }

  @Action({ rawError: true })
  async diagramGroupUpsert ({ landscapeId, versionId, diagramGroupId, props }: { landscapeId: string, versionId: string, diagramGroupId: string, props: DiagramGroupRequired & DiagramGroupPartial }) {
    const authorization = await this.context.dispatch('user/getAuthorizationHeader', undefined, { root: true })
    const { diagramGroup } = await DiagramGroupsService.diagramGroupUpsert(authorization, landscapeId, versionId, diagramGroupId, props)
    this.context.commit('setDiagramGroupVersion', diagramGroup)
  }

  @Action({ rawError: true })
  async diagramGroupUpdate ({ landscapeId, versionId, diagramGroupId, props }: { landscapeId: string, versionId: string, diagramGroupId: string, props: DiagramGroupPartial }) {
    const authorization = await this.context.dispatch('user/getAuthorizationHeader', undefined, { root: true })
    const { diagramGroup } = await DiagramGroupsService.diagramGroupUpdate(authorization, landscapeId, versionId, diagramGroupId, props)
    this.context.commit('setDiagramGroupVersion', diagramGroup)
  }

  @Action({ rawError: true })
  async diagramGroupDelete ({ landscapeId, versionId, diagramGroupId }: { landscapeId: string, versionId: string, diagramGroupId: string }) {
    const authorization = await this.context.dispatch('user/getAuthorizationHeader', undefined, { root: true })
    const { commit } = await DiagramGroupsService.diagramGroupDelete(authorization, landscapeId, versionId, diagramGroupId)
    this.context.commit('removeDiagramGroup', { commit, id: diagramGroupId })
  }

  @Action({ rawError: true })
  async diagramView ({ landscapeId, versionId, diagramId }: { landscapeId: string, versionId: string, diagramId: string }) {
    const authorization = await this.context.dispatch('user/getAuthorizationHeader', undefined, { root: true })
    await DiagramsService.diagramView(authorization, landscapeId, versionId, diagramId)
  }

  @Action({ rawError: true })
  async diagramAction ({ landscapeId, versionId, diagramId, action }: { landscapeId: string, versionId: string, diagramId: string, action?: DiagramAction }) {
    if (!env.IS_SHARE_LINK) {
      const authorization = await this.context.dispatch('user/getAuthorizationHeader', undefined, { root: true })
      await DiagramsService.diagramAction(authorization, landscapeId, versionId, diagramId, action)
    }
  }

  @Action({ rawError: true })
  async diagramsExplore ({ landscapeId, versionId, modelId, diagramId }: { landscapeId: string, versionId: string, modelId: string, diagramId?: string }) {
    const objects: Record<string, ModelObject> = this.context.rootGetters['model/objects']
    const diagrams: Record<string, Diagram> = this.context.rootGetters['diagram/diagrams']
    const diagramContents: Record<string, DiagramContent> = this.context.rootGetters['diagram/diagramContents']

    const modelObject = modelId ? objects[modelId] : Object.values(objects).find(o => o.type === 'root')
    if (!modelObject) {
      throw new Error(`Could not find model ${modelId}`)
    }

    const modelObjectDiagrams = Object.values(diagrams).filter(o => o.status === 'current' && o.modelId === modelId).sort(sort.index)
    const requestedDiagram = modelObjectDiagrams.find(o => o.id === diagramId)
    const firstDiagram = modelObjectDiagrams.filter(o => !o.groupId).find(o => o)
    const firstGroupDiagram = modelObjectDiagrams.filter(o => o.groupId).find(o => o)

    let diagram = requestedDiagram || firstDiagram || firstGroupDiagram
    let diagramContent = diagram ? diagramContents[diagram.id] : undefined

    if (diagram && !diagramContent && !env.IS_SHARE_LINK) {
      this.context.commit('setExploring', true)

      const authorization = await this.context.dispatch('user/getAuthorizationHeader', undefined, { root: true })
      const update = await DiagramContentsService.diagramContentUpdate(authorization, landscapeId, versionId, diagram.id, {}, true)

      diagramContent = update.diagramContent

      this.context.commit('setDiagramContent', diagramContent)
      this.context.commit('setExploring', false)
    } else if (!diagram && !diagramContent && !env.IS_SHARE_LINK) {
      this.context.commit('setExploring', true)

      const authorization = await this.context.dispatch('user/getAuthorizationHeader', undefined, { root: true })
      const entry = await ModelObjectsService.modelObjectDiagramsEntry(authorization, landscapeId, versionId, modelId, true, true)

      diagram = entry.diagram
      diagramContent = entry.diagramContent

      this.context.commit('setDiagram', entry.diagram)
      this.context.commit('setDiagramContent', entry.diagramContent)
      this.context.commit('setExploring', false)
    }

    if (diagram && diagramContent) {
      return {
        diagram,
        diagramContent,
        modelObject
      }
    }
  }

  @Action({ rawError: true })
  async diagramExportPng ({ landscapeId, versionId, diagramId, options }: { landscapeId: string, versionId: string, diagramId: string, options: DiagramExportPngOptions }) {
    const diagram = this.diagrams[diagramId]
    if (diagram) {
      const authorization = await this.context.dispatch('user/getAuthorizationHeader', undefined, { root: true })
      const { url } = await DiagramsService.diagramExportPng(authorization, landscapeId, versionId, diagramId, options)
      return url
    } else {
      throw new Error(`Could not find diagram ${diagram}`)
    }
  }

  @Action({ rawError: true })
  async diagramThumbnailGet ({ landscapeId, versionId, diagramId }: { landscapeId: string, versionId: string, diagramId: string }) {
    const authorization = await this.context.dispatch('user/getAuthorizationHeader', undefined, { root: true })
    const { thumbnail } = await DiagramsService.diagramThumbnailGet(authorization, landscapeId, versionId, diagramId)

    this.context.commit('setDiagramThumbnails', {
      [diagramId]: thumbnail
    })

    return thumbnail
  }

  @Action({ rawError: true })
  async diagramThumbnailsList ({ landscapeId, versionId, filter }: { landscapeId: string, versionId: string, filter?: DiagramFilter }) {
    if (env.IS_SHARE_LINK) {
      return this.diagramThumbnails
    } else {
      try {
        this.context.commit('setDiagramThumbnailsListStatus', Status.loading({
          landscapeId
        }))

        const authorization = await this.context.dispatch('user/getAuthorizationHeader', undefined, { root: true })
        const promise = DiagramsService.diagramThumbnailsList(authorization, landscapeId, versionId, filter)

        this.context.commit('setDiagramThumbnailsListStatus', Status.loading({
          landscapeId,
          promise
        }))

        const { thumbnails } = await promise
        this.context.commit('setDiagramThumbnails', thumbnails)

        this.context.commit('setDiagramThumbnailsListStatus', Status.success())

        return thumbnails
      } catch (err: any) {
        if (!(err instanceof CancelError)) {
          const message = err.body?.message || err.message
          this.context.commit('setDiagramThumbnailsListStatus', Status.error(message))
          this.context.commit('alert/pushAlert', { color: 'error', message }, { root: true })
          throw err
        }
      }
    }
  }
}
